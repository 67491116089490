.header h2 {
    text-align: right;
    font-weight: 400;
    font-size: 24px;
    white-space: nowrap;
    text-transform: capitalize;
    color: #000;
    margin-top: 0;
    margin-bottom: 0;
}

.header td {
    width: 33.333%;
}

.logoWrapper {
    max-width: 350px;
}
.logoWrapper,
.companyWrapper {
    text-align: center;
}

.addressWrapper {
    vertical-align: top;
}

.addressWrapper > section,
.companyWrapper > section {
    border: none;
    padding: 0;
    margin-bottom: 1rem;
}

.companyWrapper address {
    width: 100%;
    flex: 1 1 100%;
}

.barcodeWrapper img {
    margin: 0 auto;
}

.poInfo {
    text-align: right;
}

.poInfo p {
    font-size: 15px;
    text-align: right;
    line-height: 24px;
    margin-bottom: 5px;
}
.poInfo p span {
    border: 2px solid #c4c4c4;
    display: inline-block;
    height: 24px;
    margin-left: 15px;
    min-width: 150px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
